.languagesAccordion {
  border-top: 1px solid var(--wds-color-black-600);
  > div {
    width: 100%;
  }
  .expanded {
    font-weight: 700 !important;
  }
  .languages {
    row-gap: var(--wds-space-400);
    column-gap: 58px;
    flex-wrap: wrap;
    a {
      text-decoration: none;
      width: 113px;
    }
    .selected {
      color: var(--wsr-color-B10);
    }
  }
  div[id^='react-collapsed-panel'] {
    > div {
      background-color: var(--wds-color-white) !important;
    }
  }
}
