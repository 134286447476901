.wrapper {
  padding: var(--wds-space-400);
  box-sizing: border-box;
  border-bottom: 1px solid var(--wds-color-black-600);
  column-gap: var(--wds-space-300) !important;
  .profileName {
    width: calc(
      100vw - 2 * var(--wds-space-400) - var(--wds-space-1000) -
        var(--wds-space-300)
    );
  }
  a {
    color: var(--wds-color-black-100);
    line-height: var(--wds-space-400);
  }
}
