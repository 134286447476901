.logoCTA {
  height: 29px;
}

.textButton {
  &:hover {
    color: var(--wsr-color-B10) !important;
  }
  &:active {
    font-weight: 700 !important;
    color: var(--wsr-color-D10) !important;
  }
}
