@import './../../../styles/variables.module.scss';

.breadcrumbItem {
  min-width: 48px;
  overflow: hidden;
  flex-shrink: 1000;
  height: 100%;

  &:first-of-type {
    flex-shrink: 0;
    min-width: fit-content;
  }

  &:last-of-type {
    flex-shrink: 1;
  }

  li {
    list-style-type: none;
  }

  .collapseItem {
    display: flex;
    align-items: center;
    text-decoration: none;
    span {
      padding: 0;
    }
  }

  .breadcrumbItemChevron {
    padding: 0 var(--wds-space-100);
    @include rtl() {
      transform: rotate(180deg);
    }

    svg {
      color: var(--wds-color-black-500);
    }
  }

  .breadcrumbItemText {
    white-space: nowrap;
    color: var(--wds-color-black-100) !important;
    font-weight: 400;
    height: 100%;
    box-sizing: border-box;
    padding: 3px var(--wds-space-100);
    border-radius: 4px;

    &.breadcrumbItemTextLink {
      &:hover,
      &:focus {
        background-color: var(--wds-color-blue-500);
        color: var(--wds-color-blue-100) !important;
      }

      &:active {
        color: var(--wds-color-blue-50) !important;
      }
    }
  }
}

@include small-screen() {
  .breadcrumbItem {
    .breadcrumbItemText {
      font-size: 12px !important;
    }

    .breadcrumbItemChevron {
      padding-inline-end: 0px;
      padding-inline-start: 0px;
    }
  }
}

@include mobile-only() {
  .breadcrumbItem {
    overflow: visible;
    min-width: fit-content;

    .collapseItem {
      div {
        overflow: visible;
      }
    }

    .breadcrumbItemText {
      text-overflow: unset !important;
      overflow: visible;
      font-size: 12px !important;
    }

    .breadcrumbItemChevron {
      padding-inline-end: 0px;
      padding-inline-start: 0px;
    }
  }
}
