@import '../../../../styles/variables.module.scss';

.wrapper {
  height: calc(100vh - var(--header-size) - var(--agent-toolbar-size));
  background-color: var(--wds-color-white);
  width: 100vw;
  position: fixed;
  top: calc(var(--header-size) + var(--agent-toolbar-size));
  left: 0;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  > div {
    width: 100%;
  }
  [data-hook='header-accordion'] {
    > div {
      border-bottom: unset;
    }
    div[id^='react-collapsed-panel'] {
      > div {
        background-color: var(--wds-color-white);
      }
    }
  }
  [data-hook='title'] {
    font-weight: 400 !important;
  }
  [data-hook='accordion-toggle-button'] {
    height: var(--wds-space-400);
    width: var(--wds-space-400);
  }
  [data-hook='children'] {
    padding: var(--wds-space-200) var(--wds-space-400);
  }
  .expanded {
    font-weight: 700 !important;
  }
  .externalLink {
    padding: var(--wds-space-200) var(--wds-space-400);
    line-height: var(--wds-space-600);
    color: var(--wds-color-black-100);
    text-decoration: none;
  }
  .externalLink.divider {
    border-bottom: 1px solid var(--wds-color-black-600);
  }
  .loginWrapper {
    padding: var(--wds-space-300) var(--wds-space-400);
    border-top: 1px solid var(--wds-color-black-600);
    box-sizing: border-box;
  }
}
