@import '../../../../../styles/variables.module.scss';

.searchBarModalBody {
  .searchBarModalContent {
    justify-content: flex-start;
    @include mobile-only() {
      align-items: center;
    }
  }
  row-gap: var(--wds-space-400) !important;
  @include mobile-only() {
    row-gap: var(--wds-space-700) !important;
  }
  .searchWrapper {
    @include mobile-only() {
      position: relative;
    }
    .searchInput {
      border-radius: 30px !important;
      width: 476px;
      &.innerButton {
        width: 100%;
      }
      @include mobile-only() {
        width: 100%;
      }
      input {
        @include mobile-only() {
          margin-inline-end: 32px;
          font-size: 14px !important;
        }
      }
    }
    .searchCTA {
      @include rtl() {
        transform: rotate(180deg);
      }
      @include mobile-only() {
        position: absolute;
        right: var(--wds-space-150);
        top: var(--wds-space-100);
        margin-bottom: var(--wds-space-100);
        width: var(--wds-space-500);
        height: var(--wds-space-500);
        @include rtl() {
          right: unset;
          left: var(--wds-space-100);
        }
      }
    }

    .searchCTA_AI {
      width: var(--wds-space-500);
      height: var(--wds-space-500);

      @include rtl() {
        transform: rotate(180deg);
      }

      svg {
        width: var(--wds-space-300);
      }
    }
  }
  .searchBarModalHint {
    justify-content: flex-start !important;
    svg {
      color: var(--wds-color-black-200);
      height: 18px;
      width: 18px;
      flex-shrink: 0;
    }
    @include mobile-only() {
      padding-inline-start: unset;
      text-align: center;
      justify-content: center !important;
      width: 90%;
    }
    span {
      color: var(--wds-color-black-200);
    }
    padding-inline-start: var(--wds-space-200);
  }
  .searchBarModalBadges {
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 11px;
    @include mobile-only() {
      gap: var(--wds-space-200);
      justify-content: center;
    }

    .searchBarModalBadge {
      max-width: 100%;
      cursor: pointer;
      span {
        letter-spacing: 0;
      }

      @include mobile-only() {
        &:nth-child(n + 4) {
          display: none;
        }
      }
    }
  }
}
