@import '../../styles/variables.module.scss';

.wrapper {
  padding: 17px var(--wds-space-400);
  border-bottom: 1px solid var(--wsr-color-D55);
  position: sticky;
  top: var(--agent-toolbar-size);
  background-color: var(--wsr-color-D80);
  z-index: 4001;
  align-items: center;
  @include rtl() {
    direction: rtl;
  }
}

.wrapper.notHomepage {
  box-shadow: 0px 6px 6px 0px rgba(0, 6, 36, 0.05),
    0px 0px 18px 0px rgba(0, 6, 36, 0.1);
}
