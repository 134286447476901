@import '../../styles/variables.module.scss';

@keyframes shakeY {
  from,
  to {
    transform: translate3d(0, 0, 0);
  }
  10%,
  50% {
    transform: translate3d(0, 0, 0);
  }
  30%,
  70% {
    transform: translate3d(0, 10px, 0);
  }
}

.wrapper {
  background-size: cover;
  background-color: var(--wds-color-white);
  margin: 0 auto;
  box-sizing: border-box;
  padding: 108px var(--wds-space-1400) var(--wds-space-1400);
  max-width: calc(1200px + var(--wds-space-1400) * 2);
  height: calc(100vh - 71px);
  width: 100%;
  overflow: hidden;

  @include mobile-only() {
    padding-top: 180px;
    padding-bottom: 171px;
    height: calc(100vh - 51px);
  }

  @include small-screen() {
    height: calc(100vh - 51px);
    margin: var(--wds-space-300) auto;
    padding: 0;
  }
  .personalizedTitle {
    @include Header1Bold();
    text-align: center;
    margin-bottom: var(--wds-space-500);

    @include mobile-landscape() {
      width: 370px;
      @include Header2Bold();
    }
    @include mobile-only() {
      @include Header2Bold();
      display: flex;
      justify-content: center;
      width: 300px;
    }

    @include small-screen() {
      margin-bottom: var(--wds-space-300);
    }

    :global(.highlight) {
      color: var(--wds-color-blue-100);
    }
  }

  .searchBarWrapper {
    padding-bottom: 108px;
    align-items: center;

    @include small-screen() {
      padding-bottom: var(--wds-space-800);
    }
    .searchBarWithHint {
      justify-content: center;
      @include mobile-only() {
        max-width: 100vw;
      }
    }

    .searchBar {
      align-items: center;
      @include mobile-only() {
        width: 100vw;
      }

      .searchInput {
        border-radius: 100px;
        height: var(--wds-space-1000);
        padding-inline-start: var(--wds-space-50);
        margin-inline-end: 8px;
        width: 600px;
        @include mobile-only() {
          width: 100%;
          margin: 0 var(--wds-space-400);
          height: var(--wds-space-800);
        }
      }

      .searchArrowIcon,
      .searchArrowIconAI {
        @include rtl() {
          transform: rotate(180deg);
        }
      }

      .searchArrowIcon {
        height: var(--wds-space-1000);
        width: var(--wds-space-1000);
        @include mobile-only() {
          width: var(--wds-space-600);
          height: var(--wds-space-600);
          svg {
            width: var(--wds-space-400);
            height: var(--wds-space-400);
          }
        }
      }
      .searchArrowIconAI {
        width: var(--wds-space-700);
        height: var(--wds-space-700);
        svg {
          height: var(--wds-space-400);
          width: var(--wds-space-400);
        }
      }
    }
    .searchHint {
      display: flex;
      flex-direction: row;
      align-items: center;
      color: var(--wds-color-black-200);
      padding-top: var(--wds-space-100);
      padding-inline-start: var(--wds-space-300);
      @include mobile-only() {
        padding-inline-start: unset;
        justify-content: center;
        text-align: center;
        width: 100vw;
        padding: var(--wds-space-100) 5vw 0 5vw;
        box-sizing: border-box;
      }

      .hintIcon {
        width: var(--wds-space-400);
      }

      .hintText {
        color: var(--wds-color-black-200);
      }
      svg {
        path {
          fill: var(--wds-color-black-200);
        }
      }
    }
    .suggestionWrapper {
      @include desktop-only() {
        width: 850px;
      }

      @include small-screen() {
        max-width: 850px;
        box-sizing: border-box;
      }

      @include mobile-landscape() {
        padding-top: 3px !important;
      }

      @include mobile-only() {
        padding-top: var(--wds-space-800);
      }
      padding: var(--wds-space-500) var(--wds-space-800) 0 var(--wds-space-800);
      flex-wrap: wrap;
      justify-content: center;

      .suggestion {
        @include mobile-only() {
          &:nth-child(n + 4) {
            display: none;
          }
        }

        @include rtl() {
          span {
            font-size: 12px !important;
          }
        }
        margin-inline-end: var(--wds-space-200);
        margin-bottom: var(--wds-space-200);
        cursor: pointer;
      }
    }
  }

  .exploreMoreContainer {
    width: 150px;
    position: absolute;
    bottom: var(--wds-space-700);

    @include mobile-landscape() {
      bottom: 0 !important;
    }

    @include mobile-only() {
      bottom: var(--wds-space-300);
    }

    .exploreMore {
      cursor: pointer;
      color: var(--wds-color-blue-50);
      align-items: center;

      .exploreMoreText {
        position: relative;
        bottom: -var(--wds-space-100);
      }

      .exploreMoreIcon {
        height: var(--wds-space-600);
        width: var(--wds-space-600);
      }

      &:hover {
        .exploreMoreText {
          text-decoration: underline;
        }
        .exploreMoreIcon {
          animation: shakeY 1s;
        }
      }
    }
  }
}

.dividerContainer {
  justify-content: center;
  .divider {
    max-width: 90% !important;
    margin: 0 auto;
  }
}
