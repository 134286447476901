@import '../../../../styles/variables.module.scss';

.loggedIn {
  @include rtl() {
    direction: rtl;
  }
  .avatar {
    cursor: pointer;
  }
  [data-hook='list-item-action-title'] {
    height: 24px;
  }
  [data-hook^='dropdown-item-'] {
    &:hover {
      button {
        background-color: var(--wsr-color-B40) !important;
      }
      svg {
        fill: var(--wsr-color-B10);
      }
      span {
        color: var(--wsr-color-B10) !important;
      }
    }
  }
}
