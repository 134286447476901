.items {
  column-gap: var(--wds-space-300) !important;
  row-gap: var(--wds-space-400) !important;
  flex-wrap: wrap;
  a {
    text-decoration: none;
  }
  .item {
    height: var(--wds-space-1300);
    width: calc((100vw - var(--wds-space-800) - var(--wds-space-300)) / 2);
    @media screen and (min-width: 560px) {
      width: calc(
        (100vw - var(--wds-space-800) - var(--wds-space-300) * 2) / 3
      );
    }
  }
}
