@import '../../../../styles/variables.module.scss';

.languageMenu {
  width: 100%;
  button {
    &:hover {
      background-color: #d6e6fe !important;
      .language {
        color: var(--wsr-color-B10);
      }
    }
  }
}
.langaugesMenuIconLoggedIn {
  position: absolute;
  left: -3.5px;
  @include rtl() {
    left: unset;
    right: -3.5px;
  }
}

.languageMenuText {
  margin-left: 26.5px;
  @include rtl() {
    margin-left: unset;
    margin-right: 26.5px;
  }
}

.languageMenuIcon {
  cursor: pointer;
  &:hover {
    color: var(--wsr-color-B10);
  }
}

.selectedLanguage {
  color: var(--wsr-color-B10);
}

.loggedInButton {
  width: 100% !important;
  background-color: inherit;
  display: flex;
  align-items: center;
  justify-content: space-between;
  &:hover {
    color: var(--wsr-color-B10) !important;
  }
  [data-hook='suffix-icon'] {
    margin-left: auto !important;
  }
}
