$header-right-width: 433px;

.expandableMenu {
  position: absolute;
  top: 71px;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: transparent;
  visibility: hidden;
  opacity: 0;
  transition: background-color 0.3s 0.1s;
  transition: opacity 0.3s ease-in-out;
  .itemsAndPreview {
    width: 100%;
    height: 438px;
    background-color: var(--wsr-color-D80);
    justify-content: space-between;
    .items {
      padding: var(--wds-space-800);
      width: calc(100vw - $header-right-width);
      height: 438px;
      box-sizing: border-box;
      row-gap: var(--wds-space-900);
      column-gap: var(--wds-space-1100);
      flex-wrap: wrap;
      align-content: flex-start;
      @media screen and (min-width: 1600px) {
        width: 1167px;
      }
      @media screen and (max-width: 1365px) {
        width: 100vw;
        column-gap: var(--wds-space-800);
      }
      .item {
        display: flex;
        height: var(--wds-space-1300);
        width: calc((100% - 2 * var(--wds-space-1100)) / 3);
        @media screen and (max-width: 1365px) {
          width: calc((100% - 2 * var(--wds-space-800)) / 3);
        }
        overflow: hidden;
        a {
          text-decoration: none;
          width: 100%;
        }
      }
    }
    .preview {
      padding: var(--wds-space-900) 51.5px;
      width: $header-right-width;
      box-sizing: border-box;
      background-color: var(--Color-Black-color-black-750, #f6f7f9);
      position: relative;
      overflow: hidden;
      @media screen and (max-width: 1365px) {
        width: 0;
        display: none;
      }
      .previewImage.resources {
        height: 200px;
        top: 119px;
        border-radius: 8px;
      }
      .previewImage.topics {
        height: 330px;
        top: var(--wds-space-900);
        border-radius: 30px;
        object-fit: cover;
      }
      .previewImage {
        width: 330px;
        position: absolute;
        left: 50px;
        border-radius: 8px;
        opacity: 0;
        transition: opacity 0.7s ease-in-out;
      }
      .show {
        opacity: 1;
      }
    }
  }
}
.showMenu {
  background-color: var(--wsr-color-D10-66);
  visibility: visible;
  opacity: 1;
}
