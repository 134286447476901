@import '~@wix/answers-article-viewer/src/style';
@import './custom.scss';
@import '~@wix/answers-common-components/src/user-toolbar/style';
@import '~@wix/answers-common-components/src/contact-form/style';
@import '~@wix/design-system/styles-default.global.css';
@import '../components/AgentToolbar/index.module.scss';
@import './variables.module.scss';

body {
  margin: 0;
  font-family: Madefor, 'Helvetica Neue', Helvetica, Arial;
  font-weight: var(--wix-font-weight-regular, 400);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.content {
  margin-top: var(--agent-toolbar-size);
  width: 100vw;
}

.modal-open,
.side-panel-open {
  @include mobile-only {
    overflow: hidden;
  }

  @include small-screen() {
    overflow: hidden;
  }
}

.portal-search-bar-modal {
  > div {
    z-index: $z-index-top !important;
  }

  [class$='Modal---screen-6-mobile'] {
    padding: var(--wds-space-400) !important;
  }
}

footer {
  border-top: 1px solid var(--wds-color-black-600) !important;
}

:target {
  scroll-margin-top: 100px;
}

[data-hook='article-page-sidebar-on-left-side'] {
  @media screen and (min-width: $max-desktop-width) {
    .component {
      max-width: $article-content-width !important;
    }
  }
}

[data-hook='popover-content'] {
  z-index: $z-index-top + 1 !important;
}
