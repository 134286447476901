@import '../../../../kb-client-nextjs/styles/variables.module.scss';

.notification {
  width: 100%;
  .closeBtn {
    display: flex;
  }
}

@include mobile-only() {
  .notification {
    .hebrewText {
      text-align: center;
      margin-left: 10px;
      margin-right: 10px;
    }
  }
}
