.SiteSelectorList {
    max-height: 240px;
    box-sizing: border-box;
    overflow-y: auto;
    overflow-x: hidden;
    padding-bottom: var(--wds-space-200);

    &.scroll {
        padding-bottom: 0;
    }

    .loadingMoreSites {
        padding: var(--wds-space-300) 0;

        .loadingMoreSitesText {
            padding-top: var(--wds-space-200);
        }
    }

    .divider {
        padding: 0 var(--wds-space-400);
    }
}