.SiteSelectorPopoverOpenButton {
  padding-inline-start: 4px;
  max-width: 100%;
  box-sizing: border-box;

  .chevronDownIcon {
    transition: transform 0.2s;
    position: relative;
    top: 1px;
    padding: 0;
    padding-inline-start: 0 !important;
    font-weight: bold;
  }

  &.SiteSelectorPopoverOpenButtonOpen {
    .chevronDownIcon {
      transform: rotate(180deg);
    }
  }

  &:not(.SiteSelectorPopoverOpenButtonOpen) {
    .chevronDownIcon {
      transform: rotate(0deg);
    }
  }
}
